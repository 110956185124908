import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { isValidArray } from "@utils/variables.helpers"

import Banner from "./Banner"

const Banners = () => {
  const query = useStaticQuery(graphql`
    query {
      allOfferBanner(filter: { status: { eq: "PUBLISHED" } }) {
        nodes {
          status
          name
          startDate
          endDate
          showIndefinitely
          html
        }
      }
    }
  `)

  const banners = query?.allOfferBanner?.nodes || []

  if (!isValidArray(banners)) {
    return null
  }

  return (
    <div>
      {banners.map((banner, i) => (
        <Banner banner={banner} key={i} />
      ))}
    </div>
  )
}

export default Banners
