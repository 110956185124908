import React from "react"

import { Icon } from "@components/icon"
import { Image } from "@components/image"

import SocialLinks from "../social-links/social-links"

import * as styles from "./mailing-list-banner.module.css"

const MailingListBanner = () => {
  return (
    <div className={styles.container}>
      <a
        href="https://firstfence.us4.list-manage.com/subscribe?u=dcfdcb60cce60745f56cf7a68&id=cabd2e4958"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.joinButton}
      >
        <span>Join Our Mailing List</span>
        <Icon id="arrow-right" width="18" height="18" />
      </a>
      <div className={styles.connectText}>CONNECT WITH US</div>
      <SocialLinks />
      <Image
        src={`https://first-fence-ltd.mo.cloudinary.net/pages/ukas-iso-9001.jpg?tx=w_160,h_120`}
        className={styles.logo}
        alt="UKAS Accredited Logo"
        width="160"
        height="120"
        loading="lazy"
      />
    </div>
  )
}

export default MailingListBanner
