import React from "react"

// import { ScrollTopButton } from "@components/scroll-top-button"

import { Footer } from "./footer"
import { Header } from "./header"
import { GoogleShopping } from "./header/google-shopping"

const Layout = ({ location, children }) => {
  return (
    <div>
      <GoogleShopping location={location} />
      <Header location={location} />
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        {children}
      </div>
      {/* <ScrollTopButton /> */}
      <Footer location={location} />
    </div>
  )
}

export default Layout
