import React, { memo } from "react"

import { Icon } from "@components/icon"
import { areEqual } from "@utils/general"

import BottomBanner from "./bottom-banner/bottom-banner"
import ContactUs from "./contact-us/contact-us"
import ImportantLinks from "./important-links/important-links"
import IsoLinks from "./iso-accreditations/iso-accreditations"
import MailingListBanner from "./mailing-list-banner/mailing-list-banner"
import TermsAndConditions from "./terms-and-conditions/terms-and-conditions"

import * as styles from "./footer.module.css"

const Footer = memo(({ location }) => {
  const isNotCheckoutPage = location?.pathname !== "/checkout"
  return (
    <div className={styles.container}>
      <BottomBanner />
      {isNotCheckoutPage ? (
        <div>
          <div className={styles.content}>
            <div>
              <div className={styles.companyLogo}>
                <Icon id="logo" width="200" height="74" />
              </div>
              <IsoLinks />
            </div>
            <ImportantLinks />
            <ContactUs />
          </div>
          <MailingListBanner />
        </div>
      ) : null}
      <TermsAndConditions />
    </div>
  )
}, areEqual)

Footer.displayName = "Footer"

export default Footer
