import React, { memo } from "react"

import { Link, graphql, useStaticQuery } from "gatsby"

import DeliveryBanner from "../delivery-banner/delivery-banner"

import * as styles from "./topbar.module.css"

const TopBar = memo(() => {
  const menusData = useStaticQuery(graphql`
    {
      topLinks: allNavigationMenu(
        sort: { sortOrder: ASC }
        filter: { location: { eq: "SIDE_MENU" }, status: { eq: "PUBLISHED" } }
      ) {
        nodes {
          sortOrder
          name
          link
          id
        }
      }
    }
  `)

  const topLinks = menusData?.topLinks?.nodes || []
  const topLinksRender = topLinks?.map((item, index) => {
    return (
      <Link to={item.link} className={styles.link} key={index}>
        {item.name}
      </Link>
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.links}>{topLinksRender}</div>
      <a className={styles.link} href="tel:01283512111">
        Call us now on: 01283 512111
      </a>
      <DeliveryBanner />
      <a href="https://portal.firstfence.co.uk" className={styles.link}>
        Portal
      </a>
    </div>
  )
})

export default TopBar
