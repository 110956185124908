import React from "react"
import { Highlight, Snippet } from "react-instantsearch"

import { navigate } from "gatsby"

import { Image } from "@components/image"
import { CONTENT_TYPES, EVENT_TYPES } from "@constants/recommender-system"
import { getPriceString } from "@utils/general"
import { sendLogToRecommender } from "@utils/recommender"

import * as styles from "./product-hit.module.css"

const ProductHit = ({ hit = {} }) => {
  const { path, image, title, price, sku, mongo_id } = hit
  const src = image ? `${image}?tx=w_100` : image

  const onClick = () => {
    sendLogToRecommender({
      event: EVENT_TYPES.PRODUCT_FROM_SEARCH_BAR,
      content_id: mongo_id,
      content_type: CONTENT_TYPES.PRODUCT,
    })
    navigate(path)
  }

  return (
    <div>
      <div onClick={onClick} className={styles.link}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <Image
              src={src}
              alt={title || "Search web icon"}
              className={styles.image}
            />
          </div>

          <div>
            <div className={styles.box}>
              <Highlight
                className={styles.highlight}
                attribute="title"
                hit={hit}
                highlightedTagName="em"
              />
            </div>

            {sku && (
              <div className={styles.skubox}>
                <div>Sku: </div>
                <div className={styles.sku}>
                  <Highlight
                    className={styles.highlight}
                    attribute="sku"
                    hit={hit}
                    highlightedTagName="em"
                  />
                </div>
              </div>
            )}
            {price && (
              <div className={styles.pricebox}>
                <div>{getPriceString(price)}</div>
              </div>
            )}
            {hit._snippetResult && (
              <Snippet
                className={styles.snippet}
                attribute="html"
                hit={hit}
                highlightedTagName="em"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductHit
