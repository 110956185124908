import React, { useEffect, useState } from "react"

import { Link } from "gatsby"

import { Icon } from "@components/icon"
import { numProductsInCart } from "@services/actions/product-actions"

import * as styles from "./basket.module.css"

const Basket = ({ location }) => {
  const [numProducts, setNumProducts] = useState(0)

  const fetchNumProducts = async () => {
    const numProducts = await numProductsInCart()
    setNumProducts(numProducts)
  }

  useEffect(() => {
    fetchNumProducts()
  }, [location])

  return (
    <Link to="/shoppingcart" className={styles.container}>
      <Icon id="cart-shopping" width="20" height="20" className={styles.icon} />
      <div className={styles.batch}>
        <span className="mb-auto mt-auto">{numProducts}</span>
      </div>
    </Link>
  )
}

export default Basket
