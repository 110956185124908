import React from "react"
import { useInstantSearch } from "react-instantsearch"

const NoResultsBoundary = ({ children, fallback }) => {
  const { results } = useInstantSearch()
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }
  return children
}

export default NoResultsBoundary
