import React from "react"
import { Highlight, Snippet } from "react-instantsearch"

import { navigate } from "gatsby"

import { Image } from "@components/image"
import { CONTENT_TYPES, EVENT_TYPES } from "@constants/recommender-system"
import { getImage } from "@utils/images"
import { sendLogToRecommender } from "@utils/recommender"

import * as styles from "./category-hit.module.css"

const CategoryHit = ({ hit = {} }) => {
  const { path, imageCollection, title, mongo_id } = hit
  const mainImage = getImage(imageCollection)
  const src = mainImage ? mainImage.url : null

  const onClick = () => {
    sendLogToRecommender({
      event: EVENT_TYPES.CATEGORY_FROM_SEARCH_BAR,
      content_id: mongo_id,
      content_type: CONTENT_TYPES.CATEGORY,
    })
    navigate(path)
  }

  return (
    <div>
      <div onClick={onClick} className={styles.link}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <Image
              src={src}
              alt={title || "Search web icon"}
              className={styles.image}
            />
          </div>

          <div>
            <div className={styles.box}>
              <Highlight
                className={styles.highlight}
                attribute="title"
                hit={hit}
                highlightedTagName="em"
              />
            </div>

            {hit._snippetResult && (
              <Snippet
                className={styles.snippet}
                attribute="html"
                hit={hit}
                highlightedTagName="em"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryHit
