import React from "react"

import { Link } from "gatsby"

import { Icon } from "@components/icon"

import * as styles from "./menu.module.css"

const Menu = ({ data }) => {
  const links = data.map((item, index) => {
    return (
      <Link to={item.link} className={styles.link} key={index}>
        {item.name}
      </Link>
    )
  })

  return (
    <div className={styles.container}>
      <button className={styles.button}>
        <Icon id="bars" width="14" height="16" />
        <span>MENU</span>
      </button>
      <div className={styles.links}>{links}</div>
    </div>
  )
}

export default Menu
