import React from "react"

import { sortAscending } from "@utils/general"

import SubSubmenu from "../sub-submenu.js/sub-submenu"

import * as styles from "./submenu.module.css"

const Submenu = ({
  navItems,
  containerClass = "",
  alignment = null,
  onCategoryClick,
}) => {
  if (!navItems || !navItems.length > 0) {
    return null
  }
  let items = sortAscending(navItems, "sortOrder")
  items = items.map((item, index) => {
    return (
      <li key={index} className={styles.item}>
        <div
          className={styles.link}
          to={item.link}
          onClick={() => onCategoryClick(item)}
        >
          {item.name}
        </div>
        {item.children && (
          <SubSubmenu
            navItems={item.children}
            onCategoryClick={onCategoryClick}
          />
        )}
      </li>
    )
  })

  let alignmentClass = ""
  if (alignment === "right") {
    alignmentClass = styles.linksAlignmentRight
  }

  return (
    <ul className={`${styles.container} ${containerClass} ${alignmentClass}`}>
      {items}
    </ul>
  )
}

export default Submenu
