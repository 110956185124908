import React, { Component, Suspense, lazy } from "react"

import { Link, navigate } from "gatsby"

import { Icon } from "@components/icon"

import Basket from "../../search-bar/basket"
import ContactUsBox from "../contact-us-box"

import * as styles from "./navbar-mobile.module.css"

const Search = lazy(() => import("./search/search"))
const NavigationRoutes = lazy(() =>
  import("./navigation-routes/navigation-routes")
)

class NavbarMobile extends Component {
  state = {
    showNavigation: false,
    showContacts: false,
    showSearch: false,
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location?.pathname !== this.props.location?.pathname ||
      prevProps.location?.state?.key !== this.props.location?.state?.key
    ) {
      this.close()
    }
  }

  componentWillUnmount() {
    this.removeClass()
  }

  showMenu = (event) => {
    if (event.target.checked) {
      this.addClass()
    } else {
      this.removeClass()
    }
    this.setState({ showNavigation: event.target.checked })
  }

  addClass() {
    document.getElementById("mainBody").className += " stop-scrolling"
  }

  removeClass() {
    document.getElementById("mainBody").className = document
      .getElementById("mainBody")
      .className.replace(/(?:^|\s)stop-scrolling(?!\S)/g, "")
  }

  showContacts = () => {
    this.addClass()
    this.setState({ showContacts: true })
  }

  showSearch = () => {
    this.addClass()
    this.setState({ showSearch: true })
  }

  handleLinkClick = () => {
    this.removeClass()
    this.setState({ showNavigation: false })
  }

  close = () => {
    this.setState({
      showContacts: false,
      showNavigation: false,
      showSearch: false,
    })
    this.removeClass()
  }

  onSearchClose = (query) => {
    this.close()
    if (query !== "") {
      navigate(`/search?${query}`, { replace: true })
    } else {
      navigate("/search")
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <input
          type="checkbox"
          checked={this.state.showNavigation}
          onChange={this.showMenu}
          className={styles.toggler}
          id="menu"
        />
        <div className={styles.menu}>
          <Link onClick={this.handleLinkClick} to="/">
            <Icon
              id="logo"
              width="110"
              height="41"
              aria-label="First Fence Home Page"
            />
          </Link>
          {this.state.showSearch ||
          this.state.showNavigation ||
          this.state.showContacts ? (
            <div
              className={`${styles.iconWrapper} ${styles.searchOpened}`}
              onClick={this.close}
              role="presentation"
            >
              <Icon id="xmark" width="20" height="20" />
              <span className={styles.iconText}>Close</span>
            </div>
          ) : (
            <div className={styles.icons}>
              <div
                onClick={this.showContacts}
                className={styles.iconWrapper}
                role="presentation"
              >
                <Icon id="phone" width="20" height="20" />
                <span className={styles.iconText}>Call</span>
              </div>
              <div
                className={styles.iconWrapper}
                onClick={this.showSearch}
                role="presentation"
              >
                <Icon id="magnifying-glass" width="20" height="20" />
                <span className={styles.iconText}>Search</span>
              </div>
              <div className={styles.iconWrapper}>
                <Basket location={this.props.location} />
                <span className={styles.iconText}>Basket</span>
              </div>
              <label
                htmlFor="menu"
                className={`${styles.iconWrapper} ${styles.hamburger}`}
              >
                <Icon id="bars" width="20" height="20" />
                <span className={styles.iconText}>Menu</span>
              </label>
            </div>
          )}
        </div>

        <ContactUsBox show={this.state.showContacts} />
        {this.state.showNavigation ? (
          <Suspense fallback={<div />}>
            <NavigationRoutes navigationList={this.props.navigationList} />
          </Suspense>
        ) : null}
        {this.state.showSearch ? (
          <Suspense fallback={<div />}>
            <Search onClose={this.onSearchClose} />
          </Suspense>
        ) : null}
      </div>
    )
  }
}

export default NavbarMobile
