export const EVENT_TYPES = {
  PRODUCT_FROM_CATEGORY: "PRODUCT_FROM_CATEGORY",
  PRODUCT_FROM_SEARCH_BAR: "PRODUCT_FROM_SEARCH_BAR",
  CATEGORY_FROM_SEARCH_BAR: "CATEGORY_FROM_SEARCH_BAR",
  PAGE_FROM_SEARCH_BAR: "PAGE_FROM_SEARCH_BAR",
  SUBCATEGORY: "SUBCATEGORY",

  GROUP_PRODUCT_ADD_TO_CART: "GROUP_PRODUCT_ADD_TO_CART",
  PRODUCT_ADD_TO_CART: "PRODUCT_ADD_TO_CART",
  PRODUCT_REMOVE_FROM_CART: "PRODUCT_REMOVE_FROM_CART",
  PRODUCT_CHECKOUT_ADDRESS: "PRODUCT_CHECKOUT_ADDRESS",
  PRODUCT_CHECKOUT_DELIVERY: "PRODUCT_CHECKOUT_DELIVERY",
  PRODUCT_CHECKOUT_CONTACT_DETAILS: "PRODUCT_CHECKOUT_CONTACT_DETAILS",
  PRODUCT_CHECKOUT_PAYMENT: "PRODUCT_CHECKOUT_PAYMENT",
  PRODUCT_SHOPPING_CART: "PRODUCT_SHOPPING_CART",
  PRODUCT_PRICE_TIER: "PRODUCT_PRICE_TIER",

  RELATED_PRODUCT: "RELATED_PRODUCT",

  ORDER_CHECKOUT_PAYMENT_LOAD: "ORDER_CHECKOUT_PAYMENT_LOAD",
  ORDER_CHECKOUT_PAYMENT: "ORDER_CHECKOUT_PAYMENT",
  ORDER_CHECKOUT_COMPLETED: "ORDER_CHECKOUT_COMPLETED",

  HOME_TOP_TILE: "HOME_TOP_TILE",
  HOME_PRODUCT_RANGES: "HOME_PRODUCT_RANGES",
  HOME_CATEGORIES: "HOME_CATEGORIES",

  NAVBAR_CATEGORY: "NAVBAR_CATEGORY",

  IWOCA_COMPLETE: "IWOCA_COMPLETE",
  PAYMENT_CANCELLED: "PAYMENT_CANCELLED",
  PAYMENT_FAILED: "PAYMENT_FAILED",

  PRODUCT_SEARCH: "PRODUCT_SEARCH",
  CATEGORY_SEARCH: "CATEGORY_SEARCH",
  CMS_PAGE_SEARCH: "CMS_PAGE_SEARCH",

  CART_ID: "CART_ID",

  CONTACT_FORM: "CONTACT_FORM",
}

export const CONTENT_TYPES = {
  PRODUCT: "PRODUCT",
  GROUP_PRODUCT: "GROUP_PRODUCT",
  CATEGORY: "CATEGORY",
  PAGE: "PAGE",
  ORDER: "ORDER",
  TOP_TILE: "TOP_TILE",
  IWOCA: "IWOCA",
  SEARCH_PAGE: "SEARCH_PAGE",
  CART: "CART",
  CONTACT_FORM: "CONTACT_FORM",
}
