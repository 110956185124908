import React from "react"

import { sortAscending } from "@utils/general"

import * as styles from "./sub-submenu.module.css"

const SubSubmenu = ({ navItems, onCategoryClick }) => {
  if (!navItems || !navItems.length > 0) {
    return null
  }
  let items = sortAscending(navItems, "sortOrder")
  items = items.map((item, index) => {
    return (
      <li key={index} className={styles.item}>
        <div
          className={styles.link}
          to={item.link}
          onClick={() => onCategoryClick(item)}
        >
          {item.name}
        </div>
      </li>
    )
  })
  return <ul className={styles.container}>{items}</ul>
}

export default SubSubmenu
