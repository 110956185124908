import React from "react"
import { useInstantSearch } from "react-instantsearch"

import * as styles from "./no-results.module.css"

const NoResults = () => {
  const { results } = useInstantSearch()
  return (
    <div className={styles.noResults}>
      No results for query &ldquo;{results?.query}&ldquo;
    </div>
  )
}

export default NoResults
