import React from "react"

import { useStaticQuery, graphql } from "gatsby"

const isTest = process.env.ENV_STATE === "test"

const SEO = ({
  title,
  description,
  metaTitle,
  meta,
  metaImage,
  path,
  children,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          keywords
          siteUrl
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = "First Fence Ltd"
  const formattedTitle =
    metaTitle || title
      ? `${metaTitle || title} | ${defaultTitle}`
      : defaultTitle

  let metaImgTags = []
  if (metaImage) {
    metaImgTags = [
      {
        property: "og:image",
        content: metaImage.url,
      },
      {
        property: "og:image:width",
        content: metaImage?.width,
      },
      {
        property: "og:image:height",
        content: metaImage?.height,
      },
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
    ]
  } else {
    metaImgTags = [
      {
        name: "twitter:card",
        content: "summary",
      },
    ]
  }

  const metaTags = [
    ...meta,
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `keywords`,
      content: site.siteMetadata.keywords.join(","),
    },
    // Facebook (og:)
    {
      property: `og:title`,
      content: formattedTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: formattedTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    ...metaImgTags,
  ]

  // Remove trailing and leading slashes from path
  const formattedPath = path?.replace(/^\/|\/$/g, "")

  return (
    <>
      <title>{formattedTitle}</title>
      {metaTags.map((metaTag, i) => {
        return <meta key={i} {...metaTag} />
      })}
      {path ? (
        <link
          rel="canonical"
          href={`${site.siteMetadata.siteUrl}/${formattedPath}`}
        />
      ) : null}
      {children}
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: isTest
    ? [
        { name: "robots", content: "noindex" },
        { name: "googlebot", content: "noindex" },
      ]
    : [],
  description: "",
}

export default SEO
