/**
 * Check for a valid array with optional parameter to check if empty.
 *
 * @param {mixed} val
 * @param {bool} checkEmpty
 * @param {int} lengthCheck
 *
 * @returns {bool}
 */
export const isValidArray = (val, checkEmpty = true, lengthCheck = 0) => {
  if (checkEmpty) {
    return Array.isArray(val) && val.length > lengthCheck
  } else {
    return Array.isArray(val)
  }
}

/**
 * see if a variable is a function
 *
 * @param {mixed} func
 * @returns {bool}
 */
export const isFunction = (func) => {
  return typeof func === "function"
}

