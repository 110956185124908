import React from "react"

import { Link } from "gatsby"

import * as styles from "./terms-and-conditions.module.css"

const TermsAndConditions = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          This site, all original artwork and other media contained within is ©
          2021 First Fence Limited. All Rights Reserved.
        </div>
        <div className={styles.text}>
          &lsquo;First Fence&lsquo; and the First Fence logo are trademarks of
          First Fence Limited.
        </div>
        <div className={styles.lastText}>
          A registered company in England and Wales No. 07142033, Vat No: GB
          985368854
        </div>
      </div>
      <div className={styles.links}>
        <Link to="/privacy-policy" className={styles.link}>
          Privacy Policy
        </Link>
        <a href="/sitemap-index.xml" className={styles.link}>
          Site Map
        </a>
        <Link to="/legal-info" className={styles.link}>
          Terms &amp; Conditions
        </Link>
      </div>
    </div>
  )
}

export default TermsAndConditions
