import React from "react"

const iconViewBoxes = {
  "arrow-down-wide-short": "0 0 576 512",
  "arrow-right": "0 0 448 512",
  "arrow-up": "0 0 384 512",
  "bag-shopping": "0 0 448 512",
  bars: "0 0 448 512",
  bolt: "0 0 448 512",
  calendar: "0 0 448 512",
  calculator: "0 0 384 512",
  "caret-right": "0 0 256 512",
  "cart-shopping": "0 0 576 512",
  check: "0 0 448 512",
  "chevron-down": "0 0 512 512",
  "chevron-left": "0 0 320 512",
  "chevron-right": "0 0 320 512",
  "chevron-up": "0 0 512 512",
  "circle-dot": "0 0 512 512",
  "circle-exclamation": "0 0 512 512",
  "circle-info": "0 0 512 512",
  circle: "0 0 512 512",
  envelope: "0 0 512 512",
  expand: "0 0 448 512",
  "file-arrow-down": "0 0 384 512",
  gift: "0 0 512 512",
  instagram: "0 0 448 512",
  "iwoca-logo": "0 0 138.31 47",
  linkedin: "0 0 448 512",
  lock: "0 0 448 512",
  logo: "0 0 200 73.9",
  "magnifying-glass": "0 0 512 512",
  "pen-to-square": "0 0 512 512",
  phone: "0 0 512 512",
  sliders: "0 0 512 512",
  "sort-down": "0 0 320 512",
  "sort-up": "0 0 320 512",
  spinner: "0 0 512 512",
  "square-checkbox": "0 0 448 512",
  "square-facebook": "0 0 448 512",
  "star-of-life": "0 0 512 512",
  star: "0 0 576 512",
  tag: "0 0 448 512",
  "trash-can": "0 0 448 512",
  "triangle-exclamation": "0 0 512 512",
  "truck-fast": "0 0 640 512",
  "x-twitter": "0 0 512 512",
  xmark: "0 0 384 512",
  youtube: "0 0 576 512",
  snowflake: "0 0 448 512",
}

const Icon = ({ id, width, height, ...props }) => {
  if (!id || !Object.keys(iconViewBoxes).includes(id)) {
    return null
  }

  const viewBox = iconViewBoxes[id] || "0 0 256 512"
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <use href={`/sprite.svg#${id}`} />
    </svg>
  )
}

Icon.displayName = "Icon"

export default Icon
