export const getImage = (imageCollection) => {
  if (!imageCollection) {
    return null
  }

  if (!imageCollection.images || imageCollection.images.length === 0) {
    return null
  }

  const mainImage = imageCollection.images.find((image) => image.isMain)
  if (mainImage) {
    return mainImage
  }

  return imageCollection.images[0]
}
