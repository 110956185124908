import React, { memo } from "react"

import { graphql, useStaticQuery } from "gatsby"

import { isLive } from "@utils/config"
import { listToTree, sortAscending } from "@utils/general"

import { NavbarDesktop } from "./navbar-desktop"
import { NavbarMobile } from "./navbar-mobile"

import * as styles from "./navbar.module.css"

const Navbar = memo(({ location }) => {
  const menusData = useStaticQuery(graphql`
    {
      allNavigation {
        nodes {
          id
          name
          parentId
          link
          color
          mobileColor
          backgroundColor
          forTesting
          navigationId
          sortOrder
          alignment
        }
      }
      allNavigationMenu(
        sort: { sortOrder: ASC }
        filter: { location: { eq: "TOP_BANNER" }, status: { eq: "PUBLISHED" } }
      ) {
        nodes {
          sortOrder
          name
          link
          id
        }
      }
    }
  `)

  const menuLinks = menusData?.allNavigationMenu?.nodes || []
  const mainLinks = menusData?.allNavigation?.nodes || []

  let mainLinksData = mainLinks.map((node) => {
    return {
      ...node,
      id: node.navigationId,
    }
  })

  if (isLive()) {
    mainLinksData = mainLinksData.filter((node) => node.forTesting !== true)
  }
  mainLinksData = sortAscending(listToTree(mainLinksData), "sortOrder")

  let menuLinksData = []
  menuLinksData = menuLinks.map((node) => {
    return {
      ...node,
      parentId: null,
    }
  })
  menuLinksData = sortAscending(listToTree(menuLinksData), "sortOrder")

  const mobileLinks = [...mainLinksData, ...menuLinksData]

  return (
    <div className={styles.container}>
      <NavbarMobile navigationList={mobileLinks} location={location} />
      <NavbarDesktop
        mainLinks={mainLinksData}
        menuLinks={menuLinks}
        location={location}
      />
    </div>
  )
})

export default Navbar
