import React from "react"

import { Link } from "gatsby"

import { Icon } from "@components/icon"

import Basket from "./basket"
import Menu from "./menu/menu"
import SearchBar from "./search-bar"

import * as styles from "./search-wrapper.module.css"

const SearchWrapper = ({ menuLinks, location }) => {
  return (
    <div className={styles.container}>
      <Link to="/">
        <Icon
          id="logo"
          width="200"
          height="74"
          className={styles.logo}
          aria-label="First Fence Home Page"
        />
      </Link>
      <div className={styles.searchBar}>
        <SearchBar />
      </div>
      <div className={styles.cardWrapper}>
        <Basket location={location} />
        <Menu data={menuLinks} />
      </div>
    </div>
  )
}

export default SearchWrapper
