import React from "react"

import { Link } from "gatsby"

import * as styles from "./bottom-banner.module.css"

const BottomBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.icon}>
          <img
            src="https://media.firstfence.co.uk/icons/header-footer/brochure.svg"
            alt="Download our Brochure icon"
            rel="preconnect"
            loading="lazy"
            width="66"
            height="55"
          />
        </div>
        <div>
          <div className={styles.header}>Brochure</div>
          <div className={styles.text}>
            Download our Brochure to see our extensive range available
          </div>
          <a
            href={`https://media.firstfence.co.uk/media/brochure/First-Fence-Ltd-Online-Brochure.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className={`btn btn-secondary ${styles.button}`}
          >
            View Online
          </a>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.icon}>
          <img
            src="https://media.firstfence.co.uk/icons/header-footer/trade-account.svg"
            alt="Trade account icon"
            rel="preconnect"
            loading="lazy"
            width="66"
            height="55"
          />
        </div>
        <div>
          <div className={styles.header}>Trade Accounts</div>
          <div className={styles.text}>
            30 Day Invoicing and a dedicated account manager, plus more great
            perks!
          </div>
          <Link
            to={`/trade-account`}
            className={`btn btn-secondary ${styles.button}`}
          >
            View Benefits &amp; Apply
          </Link>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.icon}>
          <img
            src="https://media.firstfence.co.uk/icons/header-footer/hire.svg"
            alt="hire service icon"
            rel="preconnect"
            loading="lazy"
            width="66"
            height="55"
          />
        </div>
        <div>
          <div className={styles.header}>Hire Service</div>
          <div className={styles.text}>
            Comprehensive long or short term hire available on a range of lines
          </div>
          <Link
            to={`/hire-service`}
            className={`btn btn-secondary ${styles.button}`}
          >
            Find Out More
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BottomBanner
