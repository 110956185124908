// extracted by mini-css-extract-plugin
export var box = "product-hit-module--box--bb991";
export var container = "product-hit-module--container--06a50";
export var highlight = "product-hit-module--highlight--fbf50";
export var image = "product-hit-module--image--46815";
export var imageContainer = "product-hit-module--imageContainer--1e4c0";
export var link = "product-hit-module--link--a68ee";
export var pricebox = "product-hit-module--pricebox--dc0e2";
export var sku = "product-hit-module--sku--b4b8d";
export var skubox = "product-hit-module--skubox--b11d5";
export var snippet = "product-hit-module--snippet--2c0b6";