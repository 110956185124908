import React, { memo } from "react"

import { Link } from "gatsby"

import * as styles from "./usp-bar.module.css"

const USPBar = memo(() => {
  return (
    <div className={styles.content}>
      <div className={styles.iconBox}>
        <img
          src="https://media.firstfence.co.uk/icons/header-footer/Delivery.svg"
          alt="delivery"
          width={50}
          height={50}
          loading="lazy"
          className={styles.icon}
        />
        &nbsp;&nbsp;
        <div className={styles.textBox}>
          <Link to="/delivery-and-collection-service" className={styles.link}>
            Same &amp; Next Day Delivery
          </Link>
        </div>
      </div>
      <div className={styles.iconBox}>
        <img
          src="https://media.firstfence.co.uk/icons/header-footer/sales-line.svg"
          alt="sales line"
          width={50}
          height={50}
          loading="lazy"
          className={styles.icon}
        />
        &nbsp;&nbsp;
        <div className={styles.textBox}>
          <Link to="/247-sales-line" className={styles.link}>
            24/7 Sales Line
          </Link>
        </div>
      </div>
      <div className={styles.iconBox}>
        <img
          src="https://media.firstfence.co.uk/icons/header-footer/free-collect.svg"
          alt="free collect"
          width={50}
          height={50}
          loading="lazy"
          className={styles.icon}
        />
        &nbsp;&nbsp;
        <div className={styles.textBox}>
          <Link to="/delivery-and-collection-service" className={styles.link}>
            Free Click &amp; Collect
          </Link>
        </div>
      </div>
      <div className={styles.iconBox}>
        <img
          src="https://media.firstfence.co.uk/icons/header-footer/Location.svg"
          alt="location"
          width={50}
          height={50}
          loading="lazy"
          className={styles.icon}
        />
        &nbsp;&nbsp;
        <div className={styles.textBox}>
          <Link to="/depot-locator" className={styles.link}>
            Locations Nationwide
          </Link>
        </div>
      </div>
      <div className={`${styles.iconBox} ${styles.hidden}`}>
        <img
          src="https://media.firstfence.co.uk/icons/header-footer/competitive-price.svg"
          alt="competitive price"
          width={50}
          height={50}
          loading="lazy"
          className={styles.icon}
        />
        &nbsp;&nbsp;
        <div className={styles.textBox}>
          <Link
            to="/pricing-and-supply-unprecedented-times"
            className={styles.link}
          >
            <div>Finance Available</div>
          </Link>
        </div>
      </div>
      <div className={`${styles.iconBox} ${styles.hidden}`}>
        <div className={styles.sustainBox}>
          <img
            src="https://media.firstfence.co.uk/icons/header-footer/Installation.svg"
            alt="Sustainability icon"
            width={50}
            height={50}
            loading="lazy"
            className={styles.icon}
          />
        </div>
        &nbsp;&nbsp;
        <div className={styles.textBox}>
          <Link to="/installation-service" className={styles.link}>
            Installation Service
          </Link>
        </div>
      </div>
    </div>
  )
})

export default USPBar
