import React, { Component } from "react"
import { InstantSearch, Index, SearchBox, Hits } from "react-instantsearch"

import { navigate } from "gatsby"

import { NoResultsBoundary } from "@features/search/no-results-boundary"
import searchClient from "@services/algolia-client"
import qs from "querystringify"

import NoResults from "../navbar/navbar-mobile/search/no-results"
import { CategoryHit } from "./category-hit"
import { ProductHit } from "./product-hit"
import SearchPages from "./search-pages"

import * as styles from "./search-bar.module.css"

class SearchBar extends Component {
  state = {
    showSearch: false,
    searchFocus: false,
    searchMouse: false,
  }

  componentDidMount() {
    this.removeClass()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showSearch !== this.state.showSearch &&
      this.state.showSearch === false
    ) {
      this.removeClass()
    }
  }

  mouseEnterSearch = () => {
    this.setState({
      searchMouse: true,
    })
  }

  mouseExitSearch = () => {
    this.setState({
      searchMouse: false,
    })
  }

  mouseLeaveSearch = () => {
    if (this.state.searchFocus) {
      this.setState({
        searchMouse: false,
      })
    } else {
      this.setState({
        searchMouse: false,
        showSearch: false,
      })
    }
  }

  addClass() {
    document.getElementById("mainBody").className += " stop-scrolling"
  }

  removeClass() {
    document.getElementById("mainBody").className = document
      .getElementById("mainBody")
      .className.replace(/(?:^|\s)stop-scrolling(?!\S)/g, "")
  }

  isEmpty = (e) => {
    if (e.target.value === "") {
      this.setState({
        showSearch: false,
      })
      // this.removeClass()
    } else {
      this.setState({
        showSearch: true,
        searchFocus: true,
      })
      this.addClass()
    }
  }

  reset = () => {
    this.setState({
      showSearch: false,
    })
    // this.removeClass()
  }

  blur = () => {
    if (this.state.searchMouse) {
      this.setState({
        searchFocus: false,
      })
    } else {
      this.setState({
        showSearch: false,
        searchFocus: false,
      })
      // this.removeClass()
    }
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({ showSearch: false })
    const value = e.target[0].value
    const query = qs.stringify({ q: value })
    navigate(`/search?${query}`)
  }

  closeSearch = () => {
    this.setState({ showSearch: false })
  }

  render() {
    return (
      <>
        <InstantSearch indexName="products" searchClient={searchClient}>
          <SearchBox
            searchAsYouType={true}
            onChangeCapture={this.isEmpty}
            onReset={this.reset}
            onBlur={this.blur}
            onSubmit={this.submit}
            aria-label="Search"
          />
          <div
            id="searchMainBox"
            role="presentation"
            onMouseEnter={this.mouseEnterSearch}
            onMouseLeave={this.mouseExitSearch}
            onBlur={this.mouseLeaveSearch}
            className={
              this.state.showSearch ? styles.searchWrapper : styles.hide
            }
          >
            <Index indexName="products">
              <span className={styles.pagesHeader}>PRODUCTS</span>
              <NoResultsBoundary fallback={<NoResults />}>
                <Hits
                  hitComponent={ProductHit}
                  onClick={this.closeSearch}
                  classNames={{
                    item: styles.hit,
                    list: styles.hits,
                  }}
                />
              </NoResultsBoundary>
            </Index>

            <Index indexName="category">
              <span className={styles.pagesHeader}>CATEGORIES</span>
              <NoResultsBoundary fallback={<NoResults />}>
                <Hits
                  hitComponent={CategoryHit}
                  onClick={this.closeSearch}
                  classNames={{
                    item: styles.hit,
                    list: styles.hits,
                  }}
                />
              </NoResultsBoundary>
            </Index>

            <Index indexName="pages">
              <span className={styles.pagesHeader}>PAGES</span>
              <NoResultsBoundary fallback={<NoResults />}>
                <Hits
                  hitComponent={SearchPages}
                  onClick={this.closeSearch}
                  classNames={{
                    list: `${styles.hits} ${styles.pageHits}`,
                    item: styles.hit,
                  }}
                />
              </NoResultsBoundary>
            </Index>
          </div>
        </InstantSearch>
      </>
    )
  }
}

export default SearchBar
