// Service types
export const DELIVERY = "DELIVERY"
export const COLLECTION = "COLLECTION"

// Delivery
export const SPECIFIC = "SPECIFIC"

export const DEPOTS = {
  TIPTON: {
    code: "TIPTON",
    name: "Tipton Depot",
    postcode: "DY4 7BS",
  },
  CANVEY: {
    code: "CANVEY",
    name: "Canvey Depot",
    postcode: "SS8 0QY",
  },
  BRISTOL: {
    code: "BRISTOL",
    name: "Bristol Depot",
    postcode: "BS3 5RB",
  },
  GLASGOW: {
    code: "GLASGOW",
    name: "Glasgow Depot",
    postcode: "G73 1AF",
  },
  NOTTINGHAM: {
    code: "NOTTINGHAM",
    name: "Nottingham Depot",
    postcode: "NG17 1TD",
  },
}
