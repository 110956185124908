import React, { lazy, Suspense } from "react"

import Banners from "./banners/Banners"
import { Navbar } from "./navbar"
import TopBar from "./topbar/topbar"
import USPBar from "./usp-bar/usp-bar"

const CheckoutHeader = lazy(() => import("./checkout-header/checkout-header"))
const SecureBar = lazy(() => import("./secure-bar/secure-bar"))

const Header = ({ location }) => {
  const isCheckout = location && location.pathname.startsWith("/checkout")
  const isCart = location && location.pathname.startsWith("/shoppingcart")

  return (
    <>
      {isCheckout ? (
        <Suspense fallback={<div />}>
          <CheckoutHeader />
        </Suspense>
      ) : (
        <>
          <TopBar />
          <Navbar location={location} />
          <USPBar />
          <Banners />
          <Suspense fallback={<div />}>
            {isCart ? <SecureBar /> : null}
          </Suspense>
        </>
      )}
    </>
  )
}

export default Header
