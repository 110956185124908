import { COLLECTION, DELIVERY, SPECIFIC } from "@constants/service-types"
import { TIME_SLOT } from "@constants/time-slots"

/**
 * Get formated Price as a String
 *
 * @param {number} price The float price number
 * @param {Object} config The additional config options object, optional?
 *
 * @param {string} config.prefix Prefix symbol, text that' going to be prepended before price value (default value '£'), optional?
 * @param {string} config.suffix Suffix text, text that's going to be appended after price value, optional?
 * @param {string} config.zero The text that's going to be returned if price is zero (default value '£0.00'), optional?
 * @param {string} config.removeTrailingZero Remove pennies only when they are ending .00 otherwise display pennies, optional?
 *
 */
export const getPriceString = (price, config = {}) => {
  const prefix = config.prefix || "£"
  const suffix = config.suffix || ""
  const value = getFloat(price)
  if (value === 0) {
    return config.zero === undefined ? "£0.00" : config.zero
  }
  let formatedPrice = formatPrice(value)
  if (config.removeTrailingZero) {
    formatedPrice = formatedPrice.replace(/\.00/g, "")
  }
  return prefix + formatedPrice + suffix
}

export const getPriceIncludeVATString = (price, config) => {
  const priceVAT = getPriceIncludeVAT(price)
  return getPriceString(priceVAT, config)
}

export const getPriceWithVAT = (price, config) => {
  const priceVAT = getPriceIncludeVAT(price)
  return formatPrice(priceVAT, config)
}

export const getPriceIncludeVAT = (price) => {
  return getFloat(price) * 1.2
}

export const getTAXString = (price, config) => {
  const priceVAT = getTAX(price)
  return getPriceString(priceVAT, config)
}

const getTAX = (price) => {
  return getFloat(price) * 0.2
}

export const formatPrice = (price) => {
  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
}

export const getFloat = (value) => {
  const floatValue = parseFloat(value)
  return isNaN(floatValue) ? 0 : floatValue
}

export const getInt = (value) => {
  const floatValue = parseInt(value)
  return isNaN(floatValue) ? 0 : floatValue
}

export const getBoolean = (value, defaultValue) => {
  if (value === null || value === undefined) {
    return defaultValue
  } else {
    return Boolean(value)
  }
}
export const getPriceModifier = (timeType, date) => {
  let priceModifier = 1

  if (timeType === "ANY_TIME") {
    priceModifier = 1.3
  } else if (timeType === "AM" || timeType === "PM") {
    priceModifier = 1.8
  } else if (timeType === "CUSTOM_TIME") {
    priceModifier = 4.5

    if (date) {
      const inputDate = new Date(date)

      const startTime = new Date(date)
      startTime.setHours(6, 0, 0, 0)

      const endTime = new Date(date)
      endTime.setHours(18, 0, 0, 0)

      if (inputDate > startTime && inputDate < endTime) {
        priceModifier = 2.6
      }
    }
  }

  return priceModifier
}

export const isColorDark = (color) => {
  let r, g, b
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"))
    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return false
  }
  return true
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}

export const getProductsTotal = (products = []) => {
  if (!products || !products.length > 0) {
    return 0
  }

  let totalPrice = 0
  for (let index = 0; index < products.length; index++) {
    const product = products[index]
    let priceEa = parseFloat(product.priceEa)
    if (isNaN(priceEa)) {
      priceEa = 0
    }

    let qty = parseFloat(product.qty)
    if (isNaN(qty)) {
      qty = 0
    }

    totalPrice += priceEa * qty
    const extraPrice = parseFloat(product.extraPrice)
    if (!isNaN(extraPrice)) {
      totalPrice += extraPrice
    }
  }
  return totalPrice
}

/**
 * Calculate price of selected service (Delivery or Collection)
 */
export const getServicePrice = ({ selectedService, delivery, collection }) => {
  if (selectedService === DELIVERY) {
    const {
      selectedDelivery,
      selectedDate,
      dayTime,
      timeSlot,
      selectedOffload,
    } = delivery
    let deliveryPrice = 0
    if (selectedDelivery && selectedDelivery.price) {
      deliveryPrice += selectedDelivery.price
    }

    if (selectedDate && selectedDate.price) {
      let datePrice = selectedDate.price
      if (dayTime) {
        if (dayTime !== TIME_SLOT && dayTime.priceModifier) {
          datePrice = datePrice * dayTime.priceModifier
        } else if (dayTime.type === TIME_SLOT) {
          if (timeSlot && timeSlot.priceModifier) {
            datePrice = datePrice * timeSlot.priceModifier
          }
        }
      }
      deliveryPrice += datePrice
    }

    if (selectedDelivery && selectedDelivery.type !== SPECIFIC) {
      if (dayTime && dayTime.priceModifier) {
        deliveryPrice = deliveryPrice * dayTime.priceModifier
      }
    }

    if (selectedOffload && selectedOffload.price) {
      deliveryPrice += selectedOffload.price
    }
    return deliveryPrice
  } else if (selectedService === COLLECTION) {
    const { collectionDayTime, selectedCollectionDate, selectedDepot } =
      collection
    let collectionDayTimePrice = 0
    if (collectionDayTime && collectionDayTime.price) {
      collectionDayTimePrice = collectionDayTime.price
    }

    let collectionDatePrice = 0
    if (selectedCollectionDate && selectedCollectionDate.price) {
      collectionDatePrice = selectedCollectionDate.price
    }

    let depotPrice = 0
    if (selectedDepot && selectedDepot.price) {
      depotPrice = selectedDepot.price
    }
    return collectionDayTimePrice + collectionDatePrice + depotPrice
  }
  return null
}

export const listToTree = (list) => {
  const map = {}
  let node
  const roots = []

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i // initialize the map
    list[i].children = [] // initialize the children
  }

  for (let i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.parentId !== null) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]]?.children?.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

export const sortDescending = (data, field) => {
  return data.sort((a, b) => {
    if (a[field] === b[field]) {
      return 0
    } else if (a[field] === null) {
      return 1
    } else if (b[field] === null) {
      return -1
    }

    if (a[field] > b[field]) {
      return -1
    }
    if (a[field] < b[field]) {
      return 1
    }
    return 0
  })
}

export const sortAscending = (data, field) => {
  return data.sort((a, b) => {
    if (a[field] === b[field]) {
      return 0
    } else if (a[field] === null) {
      return 1
    } else if (b[field] === null) {
      return -1
    }

    if (a[field] < b[field]) {
      return -1
    }
    if (a[field] > b[field]) {
      return 1
    }
    return 0
  })
}

export const filterStatus = (status) => {
  const isTest = process.env.ENV_STATE === "test" && status === "TEST"
  const isLive = isTest || status === "PUBLISHED"
  return isLive
}

export const areEqual = (prevProps, nextProps) =>
  JSON.stringify(prevProps) === JSON.stringify(nextProps)

export const getPriceInPence = (price) => {
  // parseInt(this.props.totalAmount.toFixed(2) * 100)
  if (price === null || price === undefined) {
    return null
  }

  return parseInt(price.toFixed(2) * 100)
}
