import React from "react"
import { Highlight, Snippet } from "react-instantsearch"

import { navigate } from "gatsby"

import { CONTENT_TYPES, EVENT_TYPES } from "@constants/recommender-system"
import { sendLogToRecommender } from "@utils/recommender"

import * as styles from "./search-pages.module.css"

const SearchPages = ({ hit }) => {
  if (hit?.path) {
    const onClick = () => {
      if (hit?.mongo_id) {
        sendLogToRecommender({
          event: EVENT_TYPES.PAGE_FROM_SEARCH_BAR,
          content_id: hit.mongo_id,
          content_type: CONTENT_TYPES.PAGE,
        })
      }
      navigate(hit.path)
    }

    return (
      <div onClick={onClick} className={styles.container}>
        <div className={styles.box}>
          <Highlight
            className={styles.highlight}
            attribute="title"
            hit={hit}
            highlightedTagName="em"
          />
        </div>
        {hit._snippetResult !== undefined ? (
          <Snippet
            className={styles.snippet}
            attribute="html"
            hit={hit}
            highlightedTagName="em"
          />
        ) : null}
      </div>
    )
  } else {
    return null
  }
}

export default SearchPages
