import React, { useState, useEffect } from "react"

import * as styles from "./delivery-banner.module.css"

const DeliveryBanner = ({ isCheckout }) => {
  const [nextDay, setNextDay] = useState("00:00:00")

  useEffect(() => {
    const hour = 14
    const min = 0

    const interval = setInterval(() => {
      const nextDayTime = calculateCountdown(hour, min)
      nextDayTime ? setNextDay(nextDayTime) : clearInterval(interval)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const calculateCountdown = (hour, min = 0) => {
    const now = new Date()
    const endDate = new Date(now)
    endDate.setHours(hour, min, 0, 0)

    if (now > endDate) {
      endDate.setDate(endDate.getDate() + 1)
      if (endDate.getDay() === 6) {
        endDate.setDate(endDate.getDate() + 2)
      } else if (endDate.getDay() === 0) {
        endDate.setDate(endDate.getDate() + 1)
      }
    }

    const diff = endDate - now
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((diff / (1000 * 60)) % 60)
    const seconds = Math.floor((diff / 1000) % 60)

    return (
      (days > 0 ? `${days} days ` : "") +
      String(hours).padStart(2, "0") +
      ":" +
      String(minutes).padStart(2, "0") +
      ":" +
      String(seconds).padStart(2, "0")
    )
  }

  if (isCheckout) {
    return (
      <div className={styles.checkoutContainer}>
        <img
          src="https://media.firstfence.co.uk/icons/truck-white.svg"
          alt="Next Day Delivery icon"
          width="23"
          height="20"
          loading="lazy"
        />
        <div className={styles.header}>WE CAN DELIVER NATIONWIDE!</div>
        <img
          src="https://media.firstfence.co.uk/icons/hourglass-half-white.svg"
          alt="Next Day Delivery icon"
          width="18"
          height="18"
          loading="lazy"
        />
        <div className={styles.textWrapper}>
          <div>
            <span>Order in the next</span>
            <span className={styles.nextDayTime}>{nextDay}</span>
            <span>for&nbsp;</span>
          </div>
          <span className={styles.nextDayText}>Next Working Day Delivery!</span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src="https://media.firstfence.co.uk/icons/truck.svg"
          alt="Next Day Delivery icon"
          width="30"
          height="26"
          loading="lazy"
          className={styles.icon}
        />
        <div className={styles.textWrapper}>
          <div>
            <span>Order in the next</span>
            <span className={styles.nextDayTime}>{nextDay}</span>
            <span>for&nbsp;</span>
          </div>
          <span className={styles.nextDayText}>Next Working Day Delivery!</span>
        </div>
      </div>
    </div>
  )
}

export default DeliveryBanner
