export const save = (field, value) => {
  if (typeof window !== 'undefined') {
    const json = JSON.stringify(value)
    localStorage.setItem(field, json)
  }
}

export const load = (field) => {
  try {
    if (typeof window !== 'undefined') {
      const json = localStorage.getItem(field)
      if (json) {
        return JSON.parse(json)
      }
    }
    return null
  } catch (err) {
    console.error("There was an issue loading the local storage", err)
    return null
  }
}
