import React from "react"

import * as styles from "./iso-accreditations.module.css"

const IsoLinks = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>ISO ACCREDITATIONS</div>
      <a
        href={`https://media.firstfence.co.uk/docs/ISO/ISO%209001.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.text}
      >
        ISO 9001 - Quality Management
      </a>
      <a
        href={`https://media.firstfence.co.uk/docs/ISO/ISO%2014001.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.text}
      >
        ISO 14001 - Environmental
      </a>
      <a
        href={`https://media.firstfence.co.uk/docs/ISO/ISO%2022301.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.text}
      >
        ISO 22301 - Business Continuity
      </a>
      <a
        href={`https://media.firstfence.co.uk/docs/ISO/ISO%2027001.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.text}
      >
        ISO 27001 - Information Security
      </a>
      <a
        href={`https://media.firstfence.co.uk/docs/ISO/ISO%2045001.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.text}
      >
        ISO 45001 - Health &amp; Safety
      </a>
    </div>
  )
}

export default IsoLinks
