import { Notyf } from "notyf"

import { axiosClient } from "../client"

export const fetchProducts = async () => {
  const notyf = new Notyf()
  let products = []
  try {
    const response = await axiosClient.get(`/carts`)
    if (response.errors) {
      throw new Error(response.errors)
    }
    if (
      response.data &&
      response.data.products &&
      response.data.products.length > 0
    ) {
      products = response.data.products
    }
  } catch (error) {
    console.error("error", error)
    notyf.open({
      type: "error",
      message: "Failed to get products from server!",
      duration: 5000,
    })
  }
  return products
}

export const updateProduct = async (product) => {
  const notyf = new Notyf()
  if (product && product.id) {
    try {
      await axiosClient.post(`/products/${product.id}`, {
        product,
      })
    } catch (error) {
      console.error("error", error)
      notyf.open({
        type: "error",
        message: "Updating of the product failed!",
        duration: 5000,
      })
    }
  } else {
    notyf.open({
      type: "error",
      message: "Updating of the product failed!",
      duration: 5000,
    })
  }
}

export const fetchRelatedProducts = async (relatedProducts) => {
  let products = []
  if (relatedProducts && relatedProducts.length > 0) {
    try {
      const response = await axiosClient.post(`/products`, {
        products: relatedProducts,
      })
      if (
        response.data &&
        response.data.products &&
        response.data.products.length > 0
      ) {
        products = response.data.products
      }
    } catch (error) {
      if (error.response) {
        //response status is an error code
        // console.log("a", error.response.status)
        // console.log("error.response", error.response)
        console.error(error.response.message)
      } else if (error.request) {
        //response not received though the request was sent
        console.error("Request Error", error.request)
      } else {
        //an error occurred when setting up the request
        console.error("Error message", error.message)
      }
      console.error(error.config)
      console.error(error.toJSON())
      // console.log("error", error)
    }
  }
  return products
}

export const removeProduct = async (id) => {
  const notyf = new Notyf()
  try {
    await axiosClient.delete(`/carts/${id}`)
    notyf.open({
      type: "success",
      message: "Product removed successfully",
      duration: 5000,
    })
  } catch (error) {
    console.error("error", error)
    notyf.open({
      type: "error",
      message: "Removing of the product failed!",
      duration: 5000,
    })
  }
}

export const numProductsInCart = async () => {
  const notyf = new Notyf()
  try {
    const response = await axiosClient.get(`/cartsize`)
    if (response.errors) {
      throw new Error(response.errors)
    }
    if (response.data && response.data.size) {
      return response.data.size
    }
  } catch (error) {
    console.error("error", error)
    notyf.open({
      type: "error",
      message: "Failed to get cart size from server!",
      duration: 5000,
    })
  }
  return 0
}
