import React from "react"

const Banner = ({ banner }) => {
  const { startDate, endDate, showIndefinitely, html } = banner
  let showBanner = true

  if (!showIndefinitely) {
    try {
      const now = new Date().getTime()
      const start = new Date(startDate).getTime()
      const end = new Date(endDate).getTime()

      showBanner = now >= start && now < end
    } catch (err) {
      console.error("There was an issue comparing dates", err)
      showBanner = false
    }
  }

  if (!showBanner) {
    return null
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

export default Banner
